/* @import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities"; */

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
}

html {
	height: 100%;
	line-height: 1.15;
	-webkit-text-size-adjust: 100%;
}

/* Sections */

body {
	height: 100%;
	font-size: 16px;
}

main {
	display: block;
}

a {
	text-decoration: none;
	background-color: transparent;
	-webkit-tap-highlight-color: transparent;
}

ul > li {
	list-style: none;
}
